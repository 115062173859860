"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GelatoLimitOrders__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "_key",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_caller",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "DepositETH",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "_key",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_inputToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_owner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "OrderCancelled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "_key",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_inputToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_owner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "_auxData",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_bought",
                type: "uint256",
            },
        ],
        name: "OrderExecuted",
        type: "event",
    },
    {
        inputs: [],
        name: "ETH_ADDRESS",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "_auxData",
                type: "bytes",
            },
        ],
        name: "canExecuteOrder",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "cancelOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "decodeOrder",
        outputs: [
            {
                internalType: "address",
                name: "module",
                type: "address",
            },
            {
                internalType: "address",
                name: "inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
            {
                internalType: "bytes32",
                name: "secret",
                type: "bytes32",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "depositEth",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_module",
                type: "address",
            },
            {
                internalType: "address",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
            {
                internalType: "bytes32",
                name: "_secret",
                type: "bytes32",
            },
        ],
        name: "encodeEthOrder",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
            {
                internalType: "bytes32",
                name: "_secret",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "encodeTokenOrder",
        outputs: [
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        name: "ethDeposits",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "_auxData",
                type: "bytes",
            },
        ],
        name: "executeOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "existOrder",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "keyOf",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IModule",
                name: "_module",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "_inputToken",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_witness",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "vaultOfOrder",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
];
class GelatoLimitOrders__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.GelatoLimitOrders__factory = GelatoLimitOrders__factory;
GelatoLimitOrders__factory.abi = _abi;
